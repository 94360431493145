import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AllCustomerCategory, CustomerCategory, CustomerCustomer, CustomerOrder, CustomerProduct } from '../../models/Managers';
import { environment } from '../../../environments/environment';
import { Child } from '../../models/User';

@Injectable({
    providedIn: 'root'
})
export class ManagersService {
    private customersCustomers: CustomerCustomer[] = [];
    private customerProducts: CustomerProduct[] = [];
    private customerCategories: CustomerCategory[] = [];
    private customerOrders: CustomerOrder[] = [];
    private customerChildren: Child[] = [];
    private optionsCustomers: string[] = [];
    private optionsProducts: string[] = [];
    private optionsCategories: string[] = [];
    private optionsOrders: string[] = [];
    private optionsChildren: string[] = [];

    private currenciesRates: {
        currency: string;
        base_currency: string;
        rate: number;
    }[] = [];

    constructor(
        private http: HttpClient
    ) {
        this.currenciesRates.push({
            currency: 'EUR',
            base_currency: 'EUR',
            rate: 1,
        });
    }

    addProduct(product: CustomerProduct) {
        this.customerProducts.push(product);
        this.optionsProducts.push(`${product.sku}, ${product.value}`);
    }

    async prepareCustomersAndProducts() {
        if (this.optionsCustomers.length === 0 || this.optionsProducts.length === 0) {
            const data = await Promise.all([
                this.getCustomersFromApi(),
                this.getProductsFromApi()
            ]);
            this.customersCustomers = data[0];
            this.customerProducts = data[1];
            this.optionsCustomers = this.customersCustomers.map(c => `${c.firstname} ${c.lastname}, ${c.customerMail}, ${c.website}`);
            this.optionsProducts = this.customerProducts.map(p => `${p.sku}, ${p.value}`);
        }
    }

    async prepareCustomersOrdersProducts() {
        if (this.optionsCustomers.length === 0 || this.optionsOrders.length === 0 || this.optionsProducts.length === 0) {
            const data = await Promise.all([
                this.getCustomersFromApi(),
                this.getProductsFromApi(),
                this.getOrdersFromApi()
            ]);
            this.customersCustomers = data[0];
            this.customerProducts = data[1];
            this.customerOrders = data[2];
            this.optionsCustomers = this.customersCustomers.map(c => `${c.firstname} ${c.lastname}, ${c.customerMail}, ${c.website}`);
            this.optionsProducts = this.customerProducts.map(p => `${p.sku}, ${p.value}`);
            this.optionsOrders = this.customerOrders.map(o => `${o.order_id}, ${o.invoice_id ? o.invoice_id : ''}`);
        }
    }
    async prepareProducts() {
        this.customerProducts = await this.getProductsFromApi();
        this.optionsProducts = this.customerProducts.map(p => `${p.sku}, ${p.value}`);
    }
    async prepareCustomers() {
        this.customersCustomers = await this.getCustomersFromApi();
        this.optionsCustomers = this.customersCustomers.map(c => `${c.firstname} ${c.lastname}, ${c.customerMail}, ${c.website}`);
    }
    async prepareCategories() {
        this.customerCategories = await this.getCategoriesFromApi();
        this.optionsCategories = this.customerCategories.map(p => `${p.category_id}, ${p.category_tree.join(' - ')}`);
    }
    async prepareOrders() {
        this.customerOrders = await this.getOrdersFromApi();
        this.optionsOrders = this.customerOrders.map(o => `${o.order_id}, ${o.invoice_id ? o.invoice_id : ''}`);
    }

    getOptionsCustomers(): string[] {
        return this.optionsCustomers;
    }
    getOptionsProducts(): string[] {
        return this.optionsProducts;
    }
    getOptionsCategories(): string[] {
        return this.optionsCategories;
    }
    getOptionsOrders(): string[] {
        return this.optionsOrders;
    }
    getOptionsChildren(): string[] {
        return this.optionsChildren;
    }

    getCustomers(): CustomerCustomer[] {
        return this.customersCustomers;
    }
    getProducts(): CustomerProduct[] {
        return this.customerProducts;
    }
    getCategories(): CustomerCategory[] {
        return this.customerCategories;
    }
    getOrders(): CustomerOrder[] {
        return this.customerOrders;
    }
    getChildren(): Child[] {
        return this.customerChildren;
    }

    setProducts(products: CustomerProduct[]): void {
        this.customerProducts = products;
        this.optionsProducts = this.customerProducts.map(p => `${p.sku}, ${p.value}`);
    }
    setCategories(categories: CustomerCategory[]): void {
        this.customerCategories = categories;
        this.optionsCategories = this.customerCategories.map(p => `${p.category_id}, ${p.category_tree.join(' - ')}`);
    }
    setCustomers(customers: CustomerCustomer[]): void {
        this.customersCustomers = customers;
        this.optionsCustomers = this.customersCustomers.map(c => `${c.firstname} ${c.lastname}, ${c.customerMail}, ${c.website}`);
    }
    setOrders(orders: CustomerOrder[]): void {
        this.customerOrders = orders;
        this.optionsOrders = this.customerOrders.map(o => o.order_id);
    }
    setChildren(children: Child[]): void {
        this.customerChildren = children;
        this.optionsChildren = this.customerChildren.map(c => `${c.firstname} ${c.lastname}, ${c.email}`);
    }

    async getCustomersFromApi(): Promise<CustomerCustomer[]> {
        try {
            return (<CustomerCustomer[]> await this.http.get(
                `https://api.${environment.baseUrl}/v1/customer/customers`
            ).toPromise());
        } catch (errorResponse) {
            console.log('ERROR getCustomersFromApi : ' + JSON.stringify(errorResponse));
            return [];
        }
    }
    async getProductsFromApi(): Promise<CustomerProduct[]> {
        try {
            return (<CustomerProduct[]> await this.http.get(
                `https://api.${environment.baseUrl}/v1/customer/products`
            ).toPromise());
        } catch (errorResponse) {
            console.log('ERROR getProductsFromApi : ' + JSON.stringify(errorResponse));
            return [];
        }
    }
    async getCategoriesFromApi(): Promise<CustomerCategory[]> {
        try {
            return (<AllCustomerCategory> await this.http.get(
                `https://api.${environment.baseUrl}/v1/customer/categories`
            ).toPromise()).data;
        } catch (errorResponse) {
            console.log('ERROR getCategoriesFromApi : ' + JSON.stringify(errorResponse));
            return [];
        }
    }
    async getOrdersFromApi(): Promise<CustomerOrder[]> {
        try {
            return (<CustomerOrder[]> await this.http.get(
                `https://api.${environment.baseUrl}/v1/customer/orders`
            ).toPromise());
        } catch (errorResponse) {
            console.log('ERROR getOrdersFromApi : ' + JSON.stringify(errorResponse));
            return [];
        }
    }

    async getCurrencyRates(currency: string): Promise<{ result: number; currency: string; }> {
        try {
            return ((await this.http.get(
                `https://api.${environment.baseUrl}/v1/data/currency?fromCurrency=EUR&toCurrency=${currency}&amount=1`
            ).toPromise()) as { result: number; currency: string; });
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            return { result: 1, currency: 'EUR' };
        }
    }

    async getMissingCurrencies(currencies: string[]) {
        // get missing rates
        const missingRates = currencies.filter(c => this.currenciesRates.findIndex(curr => curr.currency === c) < 0);
        if (missingRates.length > 0) {
            const missingRatesValues = await Promise.all(missingRates.map(mr => this.getCurrencyRates(mr)));
            for (let i = 0; i < missingRatesValues.length; i++) {
                this.currenciesRates.push({
                    base_currency: 'EUR',
                    currency: missingRatesValues[i].currency,
                    rate: missingRatesValues[i].result
                });
            }
        }
    }

    // Manage currencies
    getAmountWithConvertedCurrencies(amountsByCurrency: { [key: string]: number }, toCurrency: string): number {
        const currencies = Object.keys(amountsByCurrency);
        // only one currency
        if (currencies.length === 1) {
            return amountsByCurrency[currencies[0]];
        }

        let totalAmount = 0;
        currencies.forEach(c => {
            if (c === toCurrency) {
                totalAmount += amountsByCurrency[c];
            } else {
                totalAmount += this.convertToCurrency(amountsByCurrency[c], c, toCurrency);
            }
        });
        return totalAmount;
    }

    convertToCurrency(amount: number, fromCurrency: string, toCurrency: string) {
        const fromCurrencyValue = this.currenciesRates.find(c => c.currency === fromCurrency);
        const toCurrencyValue = this.currenciesRates.find(c => c.currency === toCurrency);
        if (fromCurrencyValue && toCurrencyValue) {
            const fromCurrencyRate = fromCurrencyValue.rate;
            const toCurrencyRate = toCurrencyValue.rate;
            const converted = amount / fromCurrencyRate * toCurrencyRate;

            return Math.round(converted * 100) / 100;
        }
        return 0;
    }
}
