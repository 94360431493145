import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { INCOTERM_ENUM } from '../../models/User';

export interface CountryLabels {
    value: string;
    label_ar: string;
    label_cn: string;
    label_en: string;
    label_es: string;
    label_fr: string;
    label_hi: string;
    label_pt: string;
    label_ru: string;
    iso2: string;
}

export class Country {
    iso2: string;
    iso3: string;
    incoterms: INCOTERM_ENUM;
    officialLanguage_iso2?: string;
    reporter: string;
    label_ar: string;
    label_cn: string;
    label_en: string;
    label_es: string;
    label_fr: string;
    label_hi: string;
    label_pt: string;
    label_ru: string;
    hs_code_type: string;
    hscode_sizes: number[];
    threshold_duty: number;
    threshold_tax: number;
    threshold_special_taxes: number; // for FRA DOM TOM
    threshold_salestax: number; // only for USA
    max_threshold_annual_revenue: number;
    threshold_order_vat_currency: string;
    threshold_duty_currency: string;
    threshold_tax_currency: string;
    threshold_special_taxes_currency: string; // for FRA DOM TOM
    threshold_salestax_currency: string; // only for USA
    threshold_annual_revenue_currency: string;
    revenueThresholdVat: number;
    eu: boolean;
    eu_nomenclature?: boolean;
    customs_unions: string;
    taxGroup: string;
    dependenceGroup: number;
    requiredDistrict: boolean;
    specialVatRule: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class ApiDataService {

    private countries: CountryLabels[] = [];
    private getCountriesUrl: string;
    private getCountryV2Url: string;

    constructor(private http: HttpClient) {
        this.getCountriesUrl = `https://api.${environment.baseUrl}/v2/data/countries`;
        this.getCountryV2Url = `https://api.${environment.baseUrl}/v2/data/country`;
    }

    initializeCountries(): Promise<void> {
        return new Promise(resolve => {
            this.http.get<CountryLabels[]>(this.getCountriesUrl).toPromise().then((res) => {
                this.countries = res;
                resolve();
            }).catch(error => {
                resolve();
            });
        });
    }

    getCountries(reinitialize = false): Promise<CountryLabels[]> {
        return new Promise(resolve => {
            if (reinitialize || this.countries.length === 0) {
                this.initializeCountries().then(() => {
                    resolve(this.countries);
                });
            } else {
                resolve(this.countries);
            }
        });
    }

    getCountry(countryIso: string): Promise<Country> {
        return new Promise(resolve => {
            this.http.get<Country>(`${this.getCountryV2Url}?iso3=${countryIso}`).toPromise().then(res => {
                resolve(res);
            }).catch(error => {
                resolve(null);
            });
        });
    }

    getCountriesInstant(): CountryLabels[] {
        return this.countries;
    }
}
