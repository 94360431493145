import { Builder } from "builder-pattern";
import { CsvFieldToSet } from "../@pages/components/csv-mapper/csv-mapper.component";
import { OrderAmountsData, ProductQuantityData } from "./Monitoring";
import { TaxContent } from "./TradeTariffRates";

export class AnnualRevenue {
    // key is a year
    [key: string]: number;
}

export class CustomerCountry {
    iso3_country: string;
    statut: boolean;
    annual_revenue: AnnualRevenue;
    locals_taxes: AnnualRevenue;
    special_taxes: AnnualRevenue;
    duties: AnnualRevenue;
    currency: string;
    tax_id: string;
    show_widget: boolean;
    uk_rules: boolean;
    eori: string;
    origin_tax: string;

    toRow() {
        const row = {
            iso3_country: this.iso3_country,
            statut: this.statut,
            currency: this.currency,
            tax_id: this.tax_id,
            show_widget: this.show_widget,
            uk_rules: this.uk_rules,
            eori: this.eori,
            origin_tax: this.origin_tax
        };

        let keys = Object.keys(this.annual_revenue);
        keys.forEach(k => {
            row['annual_revenue_' + k] = this.annual_revenue[k];
        });
        keys = this.locals_taxes ? Object.keys(this.locals_taxes) : [];
        keys.forEach(k => {
            row['locals_taxes_' + k] = this.locals_taxes[k];
        });
        keys = this.special_taxes ? Object.keys(this.special_taxes) : [];
        keys.forEach(k => {
            row['special_taxes_' + k] = this.special_taxes[k];
        });
        keys = this.duties ? Object.keys(this.duties) : [];
        keys.forEach(k => {
            row['duties_' + k] = this.duties[k];
        });

        return row;
    }
}

export class LabelsPlaces {
    sku?: number;
    name?: number;
    weight?: number;
    weight_unit?: number;
    unit_price?: number;
    quantity?: number;
}

export class FormatCsv {
    content: string[];
    originalContent: string[];
    private mappedUserFields: CsvFieldToSet[];

    constructor(csvContent: string[]) {
        this.content = csvContent;

        for (let i = 0; i < this.content.length; i++) {
            this.content[i] = this.content[i].replace(/\"/g, '');
        }
        this.originalContent = this.content.slice();
    }

    get mappedFields(): CsvFieldToSet[] {
        return this.mappedUserFields;
    }

    headerContains(expectedHeaders: string[]): boolean {
        const headers = this.content[0].split(';');
        for (let i = 0; i < expectedHeaders.length; i++) {
            if (! headers.includes(expectedHeaders[i])) {
                return false;
            }
        }
        return true;
    }

    modifyHeadersWithMappedFields(mappedFields: CsvFieldToSet[]) {
        this.mappedUserFields = mappedFields;
        // we must rebuild the csv content with new headers to handle header duplication
        const newContent: string[][] = [];
        const splittedOldContent = this.content.slice(1, this.content.length).map(c => c.split(';'));
        const headers = this.getHeaders();

        // prepare newContent
        newContent.push(mappedFields.filter(f => f.externalField).map(f => f.id));
        splittedOldContent.forEach(c => {
            newContent.push([]);
        });
        // fill newContent
        mappedFields.forEach(mf => {
            // filled field
            if (mf.externalField && mf.externalField.length > 0) {
                const index = headers.findIndex(h => h === mf.externalField);
                if (index >= 0) {
                    for (let i = 0; i < splittedOldContent.length; i++) {
                        newContent[i + 1].push(splittedOldContent[i][index]);
                    }
                }
            }
        });

        this.content = newContent.map(c => c.join(';'));
    }

    mergeOriginalCsvWithResponse(response: any[], multiResultsColumnName: string = 'multi_results', ignoreHeader: string[] = ['multi_results']): any[] {
        const newResponse = [...response];
        const headers = newResponse.length > 0 ? Object.keys(newResponse[0]) : [];
        const originalCsvHeader = this.getOriginalHeaders();
        const differenceHeaders = originalCsvHeader.filter(header => ! headers.includes(header) && ! ignoreHeader.includes(header));
        const originalJsonRows = this.getOriginalCsvAsJson();
        let indexRequests = 0;
        let multi_results = 0;
        for (let i = 0; i < newResponse.length; i++) {
            // used to guard the same request content for multiple responses
            if (multi_results <= 0) {
                multi_results = originalJsonRows[indexRequests][multiResultsColumnName] && ! isNaN(parseInt(originalJsonRows[indexRequests][multiResultsColumnName])) && parseInt(originalJsonRows[indexRequests][multiResultsColumnName]) > 0 ? parseInt(originalJsonRows[indexRequests][multiResultsColumnName]) : 1;
            }
            differenceHeaders.forEach(h => {
                newResponse[i][h] = originalJsonRows[indexRequests][h];
            });
            multi_results -= 1;
            if (multi_results === 0) {
                indexRequests += 1;
            }
        }
        return newResponse;
    }
    private getOriginalCsvAsJson(): any[] {
        const originalCsvHeader = this.getOriginalHeaders();
        const originalCsvContent = this.getOriginalContent().slice(1);
        const jsonContent: any[] = [];
        originalCsvContent.forEach(row => {
            const splittedRow = row.split(';');
            const jsonRow = {};
            for (let i = 0; i < originalCsvHeader.length; i++) {
                jsonRow[originalCsvHeader[i]] = splittedRow[i];
            }
            jsonContent.push(jsonRow);
        });
        return jsonContent;
    }

    getHeaders(): string[] {
        return this.content[0].split(';');
    }

    getContent(): string[] {
        return this.content.slice(0, this.content.length);
    }

    getOriginalHeaders(): string[] {
        return this.originalContent[0].split(';');
    }

    getOriginalContent(): string[] {
        return this.originalContent.slice(0, this.originalContent.length);
    }

    toExpectedCsvContent(): string[] {
        let separatingChar = ';';
        let labels = this.content[0].split(separatingChar);
        if (labels.length <= 1) {
            separatingChar = ',';
            labels = this.content[0].split(separatingChar);
        }

        if (separatingChar !== ';') {
            for (let i = 0; i < this.content.length; i++) {
                this.content[i] = this.content[i].split(separatingChar).join(';');
            }
        }
        return this.content.slice(0, this.content.length);
    }

    toExpectedProductCsvContent(): string[] {
        let separatingChar = ';';
        let labels = this.content[0].split(separatingChar);
        if (labels.length <= 1) {
            separatingChar = ',';
            labels = this.content[0].split(separatingChar);
        }
        let expectedLabelsIndexes: LabelsPlaces;
        // depending on CMS type
        if (labels.includes('SKU') && labels.includes('Name')) {
            expectedLabelsIndexes = this.getLabelsPlaces(labels, 'SKU', 'Name', 'Weight (kg)', null, 'Regular price', null);
        } else if (labels.includes('Référence') && labels.includes('Nom')) {
            expectedLabelsIndexes = this.getLabelsPlaces(labels, 'Référence', 'Nom', null, null, 'Montant HT', 'Quantité');
        } else if (labels.includes('Variant SKU') && labels.includes('Title')) {
            expectedLabelsIndexes = this.getLabelsPlaces(labels, 'Variant SKU', 'Title', null, 'Variant Weight Unit', 'Variant Price', 'Variant Inventory Qty');
        } else if (labels.includes('Code') && labels.includes('Name')) {
            expectedLabelsIndexes = this.getLabelsPlaces(labels, 'Code', 'Name', 'Weight', null, 'Calculated Price', null);
        }

        return this.buildCsvRowsContent(expectedLabelsIndexes, separatingChar);
    }

    private getLabelsPlaces(labels: string[], sku: string, name: string, weight: string, weightUnit: string, unitPrice: string, quantity: string): LabelsPlaces {
        return Builder(LabelsPlaces)
            .sku(sku ? labels.findIndex(value => value === sku) : undefined)
            .name(name ? labels.findIndex(value => value === name) : undefined)
            .weight(weight ? labels.findIndex(value => value === weight) : undefined)
            .weight_unit(weightUnit ? labels.findIndex(value => value === weightUnit) : undefined)
            .unit_price(unitPrice ? labels.findIndex(value => value === unitPrice) : undefined)
            .quantity(quantity ? labels.findIndex(value => value === quantity) : undefined)
            .build();
    }

    private buildCsvRowsContent(labelsIndexes: LabelsPlaces, separatingChar: string): string[] {
        // not a CMS CSV, parse the original content as an expected CSV
        if (!labelsIndexes) {
            if (separatingChar !== ';') {
                for (let i = 0; i < this.content.length; i++) {
                    this.content[i] = this.content[i].split(separatingChar).join(';');
                }
            }
            return this.content.slice();
        }

        const contentToReturn = ['sku;productID;weight;weight_unit;quantity;unit_price;currency_unit_price'];

        for (let i = 1; i < this.content.length; i++) {
            const currentContentRow = this.content[i].split(separatingChar);
            const row: string[] = [];

            row.push(labelsIndexes.sku !== undefined && labelsIndexes.sku >= 0 ? currentContentRow[labelsIndexes.sku] : '');
            row.push(labelsIndexes.name !== undefined && labelsIndexes.name >= 0 ? currentContentRow[labelsIndexes.name] : '');
            row.push(labelsIndexes.weight !== undefined && labelsIndexes.weight >= 0 ? currentContentRow[labelsIndexes.weight] : '1');
            row.push(labelsIndexes.weight_unit !== undefined && labelsIndexes.weight_unit >= 0 ? currentContentRow[labelsIndexes.weight_unit] : 'kg');
            row.push(labelsIndexes.quantity !== undefined && labelsIndexes.quantity >= 0 ? currentContentRow[labelsIndexes.quantity] : '');
            row.push(labelsIndexes.unit_price !== undefined && labelsIndexes.unit_price >= 0 ? currentContentRow[labelsIndexes.unit_price] : '');
            row.push('EUR');

            contentToReturn.push(row.join(';'));
        }

        return contentToReturn;
    }
}

export enum CUSTOMER_PRODUCT_TYPE_ENUM {
    TEXT = 'TEXT',
    SKU = 'SKU'
}

export enum PRODUCTS_SELECTED_ROWS_ACTIONS {
    DOWNLOAD_CSV = 'DOWNLOAD_CSV',
    EDIT_MULTIPLE = 'EDIT_MULTIPLE',
    EXECUTE_ACTION = 'EXECUTE_ACTION'
}


export class CustomerProductsProcessRequest {
    sku: string|string[];
}

export enum ORDER_TYPE_CUSTOMER {
    PAID = 'paidOrders',
    AWAITING = 'awaitingOrders',
    CANCELED = 'canceledOrders'
}

export class CustomerCustomer {
    customerMail: string;
    website: string;
    firstname: string;
    lastname: string;
    gender: string;
    age: number;
    phoneNumber: string;
    zipCode: string;
    address: string;
    city: string;
    state: string;
    country: string;
    companyName: string;
    companyId: string;
    companyTaxId: string;
    pro: boolean;
    paidOrders: OrderAmountsData[];
    awaitingOrders: OrderAmountsData[];
    canceledOrders: OrderAmountsData[];
    buyedProducts: ProductQuantityData[];

    toRow() {
        return {
            customerMail: this.customerMail,
            website: this.website,
            firstname: this.firstname,
            lastname: this.lastname,
            gender: this.gender,
            age: this.age,
            phoneNumber: this.phoneNumber,
            zipCode: this.zipCode,
            address: this.address,
            city: this.city,
            state: this.state,
            country: this.country,
            companyName: this.companyName,
            companyId: this.companyId,
            companyTaxId: this.companyTaxId,
            pro: this.pro,
            paidOrders: this.paidOrders.length,
            awaitingOrders: this.awaitingOrders.length,
            canceledOrders: this.canceledOrders.length,
            buyedProducts: this.buyedProducts.length
        };
    }
}

export class ProductHscodeContent {
    hscodefinder_hs_code: string;
    hscodefinder_designation: string;
    hscodefinder_hs_version: string;
    product_statut: string;
}

export class CustomerProduct {
    id: string;
    type: CUSTOMER_PRODUCT_TYPE_ENUM;
    sku: string;
    categories_id: string[];
    to_countries: { [key: string]: ProductHscodeContent };
    value: string;
    weight: number;
    weight_unit: string;
    [key: string]: any;

    toRow() {
        const row = {
            id: this.id,
            type: this.type,
            sku: this.sku,
            categories_id: this.categories_id,
            productID: this.value,
            weight: this.weight,
            weight_unit: this.weight_unit
        };

        const keys = Object.getOwnPropertyNames(this);
        const productKeys = [
            'id',
            'type',
            'productID',
            'value',
            'weight',
            'weight_unit',
            'creationDate',
            'hscodefinder',
            'dutyCalculation',
            'attribut',
            'node',
            'locals_taxes',
            'other_taxes'
        ];

        keys.forEach(k => {
            if (!productKeys.includes(k)) {
                row[k] = this[k];
            }
            if (k === 'locals_taxes' || k === 'other_taxes') {
                const arr = (this[k] as TaxContent[]);
                for (let i = 0; i < arr.length; i++) {
                    row[`${k}_label_${i + 1}`] = arr[i].label;
                    row[`${k}_percentage_${i + 1}`] = arr[i].percentage;
                }
            }
        });

        return row;
    }

    // OLD FUNCTION, NOT USED ANYMORE
    // tslint:disable-next-line: member-ordering
    /*static changeRowToCustomerProduct(row: any): CustomerProduct {
        const product = Builder(CustomerProduct)
                        .type(row.type)
                        .sku(row.sku)
                        .value(row.productID)
                        .weight(row.weight)
                        .weight_unit(row.weight_unit)
                        .build();

        const keys = Object.getOwnPropertyNames(row);
        const productKeys = [
            'id',
            'type',
            'productID',
            'value',
            'weight',
            'weight_unit',
            'creationDate',
            'hscodefinder',
            'dutyCalculation'
        ];

        const keysStartsWith = [
            'locals_taxes',
            'other_taxes'
        ];

        keys.forEach(k => {
            if (!productKeys.includes(k) && keysStartsWith.filter(ksw => k.startsWith(ksw)).length === 0) {
                row[k] = this[k];
            }
        });

        // re-add locals taxes & special taxes as arrays
        const localsTaxesCount = parseInt((keys.filter(k => k.startsWith('locals_taxes')).length / 2).toFixed(0), 10);
        if (localsTaxesCount > 0) {
            product.locals_taxes = [];
        }
        for (let i = 0; i < localsTaxesCount; i++) {
            product.locals_taxes.push({
                label: row[`locals_taxes_label_${i + 1}`],
                percentage: row[`locals_taxes_percentage_${i + 1}`]
            });
        }
        const specialTaxesCount = parseInt((keys.filter(k => k.startsWith('other_taxes')).length / 2).toFixed(0), 10);
        if (specialTaxesCount > 0) {
            product.other_taxes = [];
        }
        for (let i = 0; i < specialTaxesCount; i++) {
            product.other_taxes.push({
                label: row[`other_taxes_label_${i + 1}`],
                percentage: row[`other_taxes_percentage_${i + 1}`]
            });
        }

        return product;
    }*/
}

export class StripeLink {
    link: string;
}

export class StripeConnectedAccountInfo {
    id: string;
    default_currency: string;
    details_submitted: boolean;
}

export class InternationalCheckoutAccountInfo {
    logo_url: string;
    redirection_url: string;
    color_theme: string;
    api_key_fedex: string;
    api_key_dhl: string;
    api_key_ups: string;
    api_key_chronopost: string;
}

export class InternationalCheckoutSettings {
    storeID: string;
    stripe_connected_account_info: StripeConnectedAccountInfo;
    international_checkout_account_info: InternationalCheckoutAccountInfo;
}

export class GeneratedDocContent {
    id: string;
    signed_url: string;
    lang: string;
    date: number;
    expireTime: number;
}

export class GeneratedDocContentWithOrderID extends GeneratedDocContent {
    order_id: string;
}

export class GeneratedDocsResponse {
    certificateOfOrigin?: GeneratedDocContent[];
    cn22?: GeneratedDocContent[];
    cn23?: GeneratedDocContent[];
    exportInvoice?: GeneratedDocContent[];
    lithium?: GeneratedDocContent[];
    taxesInvoice?: GeneratedDocContent[];
    rectifiedExportInvoice?: GeneratedDocContent[];
    creditNote?: GeneratedDocContent[];
}

export class GeneratedDocsWithOrderID {
    certificateOfOrigin?: GeneratedDocContentWithOrderID[];
    cn22?: GeneratedDocContentWithOrderID[];
    cn23?: GeneratedDocContentWithOrderID[];
    exportInvoice?: GeneratedDocContentWithOrderID[];
    lithium?: GeneratedDocContentWithOrderID[];
    taxesInvoice?: GeneratedDocContentWithOrderID[];
    rectifiedExportInvoice?: GeneratedDocContentWithOrderID[];
    creditNote?: GeneratedDocContentWithOrderID[];
}

export enum CustomerOrdersServiceEnum {
    LANDED_COST = 'landedCost',
    GENERATE_DOCS = 'generateDocs',
    REMOVE_PRODUCTS_GENERATE_CREDIT_NOTE = 'removeProducts',
    DELETE_ORDERS_GENERATE_CREDIT_NOTE = 'deleteOrders',
    GENERATE_TAXES_INVOICE = 'generateTaxesInvoice'
}

export enum CustomerOrderAvailableActions {
    LANDED_COST = 1,
    GENERATE_DOCS = 2,
    GENERATE_TAXES_INVOICE = 3,
    REMOVE_PRODUCTS_GENERATE_CREDIT_NOTE = 4,
    DELETE_ORDERS_GENERATE_CREDIT_NOTE = 5
}

export class CustomerOrdersProcessProductsAssociated {
    [key: string]: string[]; // as {'order_1': ['sku_AAA', 'sku_BBB'], 'order_2': ['sku_AAA', 'sku_BBB']}
}
export class CustomerOrdersProcessRequest {
    orders_ids: string[];
    orders_products: CustomerOrdersProcessProductsAssociated;
}
export class CustomerOrderProcessResponse extends GeneratedDocsResponse {
    orderId: string;
    customerOrder: CustomerOrder;
}
export class CustomerOrderProcessErrorResponse {
    ordersWithMissingLandedCost: string[];
    ordersWithMissingRequiredFields: string[];
    rightOrdersAwaitingToDoTheProcess: string[];
}

export class OrderProduct {
    sku: string;
    quantity: number;
    unit_price: number;
    unit_price_currency: string;
    amount_duty: number;
    amount_vat: number;
    amount_specialtaxes: number;

    toRow() {
        return {
            sku: this.sku,
            quantity: this.quantity,
            unit_price: this.unit_price,
            unit_price_currency: this.unit_price_currency,
            amount_duty: this.amount_duty,
            amount_vat: this.amount_vat,
            amount_specialtaxes: this.amount_specialtaxes
        };
    }
}

export class CustomerOrder {
    order_id: string;
    invoice_id: string;
    url: string;
    order_date_hour: Date | number; // Date (display on table) or timestamp
    customer_firstname: string;
    customer_lastname: string;
    customer_address: string;
    customer_zipcode: string;
    customer_city: string;
    customer_country: string;
    customer_documents: string[];
    buyer_firstname: string;
    buyer_lastname: string;
    buyer_address: string;
    buyer_zipcode: string;
    buyer_city: string;
    buyer_country: string;
    agent_firstname: string;
    agent_lastname: string;
    agent_address: string;
    agent_zipcode: string;
    agent_city: string;
    agent_country: string;
    departure_country: string;
    arrival_country: string;
    products: OrderProduct[];
    exporting_carrier: string;
    shipping_carrier: string;
    amount_products: number;
    amount_shipping: number;
    amount_shipping_duty: number;
    amount_shipping_vat: number;
    amount_shipping_specialtaxes: number;
    amount_duty: number;
    amount_vat: number;
    amount_specialtaxes: number;
    currency: string;
    order_statut: string;
    order_update_statut: Date | number; // Date (display on table) or timestamp
    order_payment_type: string;
    order_payment_date: Date | number; // Date (display on table) or timestamp
    category_of_item: string;
    payment_type: string;
    incoterm: string;
    incoterm_delivery: string;
    landed_cost_timestamp: number;
    canceled: boolean;

    toRow() {
        return {
            order_id: this.order_id,
            invoice_id: this.invoice_id,
            url: this.url,
            order_date_hour: typeof this.order_date_hour === 'number' ?
                            new Date(this.order_date_hour) as number | Date :
                            this.order_date_hour as number | Date,
            customer_firstname: this.customer_firstname,
            customer_lastname: this.customer_lastname,
            customer_address: this.customer_address,
            customer_zipcode: this.customer_zipcode,
            customer_city: this.customer_city,
            customer_country: this.customer_country,
            buyer_firstname: this.buyer_firstname,
            buyer_lastname: this.buyer_lastname,
            buyer_address: this.buyer_address,
            buyer_zipcode: this.buyer_zipcode,
            buyer_city: this.buyer_city,
            buyer_country: this.buyer_country,
            agent_firstname: this.agent_firstname,
            agent_lastname: this.agent_lastname,
            agent_address: this.agent_address,
            agent_zipcode: this.agent_zipcode,
            agent_city: this.agent_city,
            agent_country: this.agent_country,
            departure_country: this.departure_country,
            arrival_country: this.arrival_country,
            exporting_carrier: this.exporting_carrier,
            shipping_carrier: this.shipping_carrier,
            amount_products: this.amount_products,
            amount_shipping: this.amount_shipping,
            amount_shipping_duty: this.amount_shipping_duty,
            amount_shipping_vat: this.amount_shipping_vat,
            amount_shipping_specialtaxes: this.amount_shipping_specialtaxes,
            amount_duty: this.amount_duty,
            amount_vat: this.amount_vat,
            amount_specialtaxes: this.amount_specialtaxes,
            currency: this.currency,
            order_statut: this.order_statut,
            order_update_statut: typeof this.order_update_statut === 'number' ?
                                new Date(this.order_update_statut) as number | Date :
                                this.order_update_statut as number | Date,
            order_payment_type: this.order_payment_type,
            order_payment_date: typeof this.order_payment_date === 'number' ?
                                new Date(this.order_payment_date) as number | Date :
                                this.order_payment_date as number | Date,
            category_of_item: this.category_of_item,
            payment_type: this.payment_type,
            incoterm: this.incoterm,
            incoterm_delivery: this.incoterm_delivery
        };
    }
}

export class UserPdfContentRow {
    type: string;
    id: string;
    order_id: string;
    products_ids: string;
}

export class UserPdfContent {
    mail: string;
    'pdf_type#pdf_id': string;
    date: number;
    lang: string;
    pdf_type: string;
    pdf_id: string;
    template_id: string;
    expireTime: number;
    order_id?: string;
    products_ids?: string[];

    toRow(): any {
        return Builder(UserPdfContentRow)
            .type(this.pdf_type)
            .id(this.pdf_id)
            .order_id(this.order_id ? this.order_id : '')
            .products_ids(this.products_ids ? this.products_ids.toString() : '')
            .build();
    }
}

export class UserPdfContentResponse {
    data: UserPdfContent[];
    nextIndex: any;
}

export class UserPdfUrl {
    signed_url: string;
}

// CUSTOMER CATEGORIES
export class CategoryTariffRates {
    hscode: string;
    hscode_designation: string;
    hscode_version: string;
    duties_rates: number;
    localsTax_rates: number;
    specialsTax_rates: number;
    salesTax_rates: number;
    status: string;
    transco_code: string;
    update_timestamp: number;
}

export class CustomerCategory {
    mail: string;
    node: string;
    attribut: string;
    group_id: string[];
    category_id: string;
    products_id: string[];
    category_tree: string[];
    last_update_timestamp: number | string; // string format is for rows, locale date string
    tariff_rates: {[key: string]: CategoryTariffRates};
    average_weight_kg: number;
    ecoTax_price_default_pro: number;
    ecoTax_price_default_personal: number;
    ecoTax_default: boolean | string; // string format is for rows, as 'true' or 'false'
    ecoTax_code_pro: string;
    ecoTax_subCode_pro: string;
    ecoTax_code_personal: string;
    ecoTax_price_pro: number;
    ecoTax_price_personal: number;
    ecoTax_price_currency: string;
    co2_product_kg: number;
    un_code: string[];
    hscodefinder_hs_code: string;

    toRow(): any {
        return Builder(CustomerCategory)
            .category_id(this.category_id)
            .products_id(this.products_id)
            .category_tree(this.category_tree)
            .last_update_timestamp(this.last_update_timestamp ? (new Date(this.last_update_timestamp)).toLocaleString() : undefined)
            .average_weight_kg(this.average_weight_kg)
            .ecoTax_code_pro(this.ecoTax_code_pro)
            .ecoTax_subCode_pro(this.ecoTax_subCode_pro)
            .ecoTax_code_personal(this.ecoTax_code_personal)
            .ecoTax_price_pro(this.ecoTax_price_pro)
            .ecoTax_price_personal(this.ecoTax_price_personal)
            .ecoTax_price_default_pro(this.ecoTax_price_default_pro)
            .ecoTax_price_default_personal(this.ecoTax_price_default_personal)
            .ecoTax_price_currency(this.ecoTax_price_currency)
            .ecoTax_default(this.ecoTax_default !== undefined && this.ecoTax_default !== null ? this.ecoTax_default.toString() : undefined)
            .co2_product_kg(this.co2_product_kg)
            .un_code(this.un_code)
            .build();
    }
}

export class AllCustomerCategory {
    data: CustomerCategory[];
    nextIndex: any;
}

export enum ORDERS_DATES_INTERVAL {
    ALL = 'ALL',
    LAST_THIRTY_DAYS = 'LAST_THIRTY_DAYS',
    CURRENT_MONTH = 'CURRENT_MONTH',
    LAST_NINETY_DAYS = 'LAST_NINETY_DAYS',
    CURRENT_YEAR = 'CURRENT_YEAR'
}
