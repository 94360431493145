import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../components/message/message.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Builder } from 'builder-pattern';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { UserPdfContent, UserPdfContentResponse, UserPdfContentRow, UserPdfUrl } from '../../../models/Managers';
import {saveAs} from 'file-saver';
import { CsvService } from '../../services/csv.service';
import { Subject } from 'rxjs';
@Component({
    selector: 'app-my-documents',
    templateUrl: './my-documents.component.html',
    styleUrls: ['./my-documents.component.scss'],
})
export class MyDocumentsComponent implements OnInit {
    @ViewChild('myTable', { static: false }) table: any;

    // to request the API in order to get the next data
    nextIndex: any;

    // table rows
    rows: UserPdfContentRow[] = [];
    columnsData: string[] = [];

    // expand row
    expandedRowId: string = null;
    rowDetailHeight = 0;

    // actions
    selectedAction = 0;
    availableActions: string[] = [];

    // frozen column & modal
    modalRef: BsModalRef;
    tempFrozenColumn = '';
    frozenColumn = '';

    unknown_error: string;
    columnsLabels: any;

    private isRetrievingData = false;

    requestSelectedRowsSubject: Subject<void> = new Subject<void>();

    // ONLY ORDERS
    // expanded rows contents
    productsDetails: {[key: string]: {sku: string; quantity: number; unit_price: number; unit_price_currency: string; }[]};

    constructor(
        private http: HttpClient,
        private _notification: MessageService,
        private translate: TranslateService,
        private csvService: CsvService,
        private modalService: BsModalService
    ) {
        this.productsDetails = {};
    }

    async ngOnInit() {
        this.nextIndex = undefined;
        this.rows = [];

        this.initTranslation();
        this.setColumnsData();
        await this.retrieveData();
    }

    // are rows empty?
    emptyRows(): boolean {
        if (this.rows === null || (this.rows && this.rows.length === 0)) {
            return true;
        }
        return false;
    }

    async retrieveData() {
        this.isRetrievingData = true;
        const userPdfs: UserPdfContentResponse = await this.getData();
        if (userPdfs !== null) {
            this.nextIndex = userPdfs.nextIndex;
            userPdfs.data.forEach((userPdf) => {
                const userPdfContent = this.buildData(userPdf);
                this.rows.push(userPdfContent.toRow());
            });
            this.rows = [...this.rows];
        }
        this.isRetrievingData = false;
    }

    initTranslation() {
        // get error messages
        this.translate.get('messages').toPromise().then((messages) => {
            this.unknown_error = messages.notification.unknown_error;
        });
        this.translate.get('myDocuments').toPromise().then(myDocuments => {
            this.columnsLabels = myDocuments.inputForm;
            this.availableActions = Object.values(myDocuments.actions);
        });

        // handle update language
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            const messages = event.translations['messages'];
            this.unknown_error = messages.notification.unknown_error;
            this.columnsLabels = event.translations['myDocuments']['inputForm'];
            this.availableActions = Object.values(event.translations['myDocuments']['actions']);
        });
    }

    onScrollToEnd() {
        if (!this.isRetrievingData && this.nextIndex !== undefined) {
            this.retrieveData();
        }
    }

    // DATA FUNCTIONS
    buildData(data: UserPdfContent): UserPdfContent {
        return Builder(UserPdfContent)
            .mail(data.mail)
            ['pdf_type#pdf_id'](data['pdf_type#pdf_id'])
            .pdf_type(data.pdf_type)
            .pdf_id(data.pdf_id)
            .date(data.date)
            .expireTime(data.expireTime)
            .template_id(data.template_id)
            .lang(data.lang)
            .order_id(data.order_id)
            .products_ids(data.products_ids)
            .build();
    }
    setColumnsData() {
        this.columnsData = [
            'type',
            'id',
            'order_id',
            'products_ids'
        ];
    }
    async getData(): Promise<UserPdfContentResponse> {
        try {
            if (this.nextIndex && this.nextIndex['pdf_type#pdf_id']) {
                return (<UserPdfContentResponse> await this.http.get(
                    `https://api.${environment.baseUrl}/v1/pdf?index=${this.nextIndex['pdf_type#pdf_id']}`
                ).toPromise());
            }
            return (<UserPdfContentResponse> await this.http.get(
                `https://api.${environment.baseUrl}/v1/pdf`
            ).toPromise());
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return null;
        }
    }
    async getPdfUrl(userPdfContent: UserPdfContentRow): Promise<UserPdfUrl> {
        try {
            return (<UserPdfUrl> await this.http.get(
                `https://api.${environment.baseUrl}/v1/pdf/url/${userPdfContent.type}/${userPdfContent.id}`
            ).toPromise());
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return null;
        }
    }

    // MODAL & FROZEN COLUMN
    openModal(template: TemplateRef<any>, type: string) {
        const config = {class: 'modal-lg', animated: false, backdrop: 'static', keyboard: false} as ModalOptions;
        if (type === 'pinColumn') {
            this.tempFrozenColumn = '';
        }
        this.modalRef = this.modalService.show(template, config);
    }

    closeModal() {
        this.modalRef.hide();
    }
    onValidFrozenColumn() {
        this.frozenColumn = this.tempFrozenColumn;
        this.tempFrozenColumn = '';
        this.closeModal();
    }

    async downloadPdf(rowId: string) {
        const userPdfContent = this.rows.find(r => r.id === rowId);
        const pdfUrl = await this.getPdfUrl(userPdfContent);
        if (pdfUrl) {
            saveAs(pdfUrl.signed_url.substring(0, pdfUrl.signed_url.indexOf('?Expires=')), `${userPdfContent.type}_${userPdfContent.id}.pdf`);
        }
    }

    // create a CSV file with selected lines and send it back to the user
    downloadCsv(rowsIds: string[]) {
        if (rowsIds.length > 0) {
            const csvResponse = this.rows.filter(r => rowsIds.includes(r.id));
            const csvFileName = 'my_documents';
            return this.csvService.downloadFile(csvResponse, csvFileName);
        }
    }

    onDownloadSelectedRowsAsCsv() {
        this.requestSelectedRowsSubject.next();
    }

    selectAction(action: number) {
        this.selectedAction = action;
    }
}
