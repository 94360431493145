import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MessageService} from '../../components/message/message.service';
import {ChildWithConsumptions, User} from '../../../models/User';
import {UserService} from '../../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {ManageChildService} from '../../services/manage-child.service';
import { ApiDataService, CountryLabels } from '../../services/api-data.service';
import { DOCUMENTATIONS_GUIDES } from '../documentation-guide/documentation-guide.component';
import { ConsumptionCreditEnum, DailyRequestsPerUserAndOrdersResponse, DailyRequestsPerUserGroupedByHttpCode, RequestsNumberPerUser, RequestsPeriodCountValue, RequestsPerPeriodResponse, UserLastConsecutiveRequestErrorContent, UserLastConsecutiveRequestsError } from '../../../models/Monitoring';
import { environment } from '../../../../environments/environment';
import { Builder } from 'builder-pattern';

enum CHECKOUT_ENUM {
    CANCELED = 'canceled',
    SUCCESS = 'success',
    FAILED = 'failed',
}
@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
    user: User;
    checkout = {
        extra: {},
        plan: {},
        card: {}
    };
    theme: string;
    unknown_error: string;
    langs: Array<{ value: string, label: string }>;
    apiLang: string;
    displayEmbededWebsite = false;

    children: ChildWithConsumptions[] = [];
    apiCountries: CountryLabels[] = [];
    currencies: { value: string; label: string; }[] = [];
    requestsEndpoints: { [key: string]: {saas: string; api: string; showApi: boolean; showSaas: boolean }; } = {};

    // Super Admin vars
    superAdminData: { periodOrders: number; periodRequests: number; previousPeriodOrdersDiff: number; previousPeriodRequestsDiff: number; } = { periodOrders: 0, periodRequests: 0, previousPeriodOrdersDiff: 0, previousPeriodRequestsDiff: 0 };
    superAdminRequestsPeriodCount: RequestsPerPeriodResponse[] = [];
    superAdminUsersWithLastConsecutiveRequestsError: UserLastConsecutiveRequestsError[] = []
    selectedUserConsecutiveErrors: string;
    selectedUserLastConsecutiveRequestsWithError: UserLastConsecutiveRequestErrorContent[] = [];

    // Super Admin daily requests table content
    dailyRequestsRows: { endpoint: string; totalRequests: number; }[] = [];
    dailyRequestsDetails: { [key: string]: { email: string; firstname: string; lastname: string; httpCode: string; requestsNumber: number; }[] } = {};
    dailyRequestsColumns: string[] = [];
    dailyRequestsColumnsLabels: { [key: string]: string; };
    dailyRequestsDetailsColumns: string[] = [];
    dailyRequestsDetailsColumnsLabels: { [key: string]: string; };
    dailyRequestSelectedDate: string[];

    constructor(
        private http: HttpClient,
        private _notification: MessageService,
        private userService: UserService,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private manageChildService: ManageChildService,
        private apiDataService: ApiDataService,
        private router: Router
    ) {}

    async getApiCountries() {
        this.apiCountries = await this.apiDataService.getCountries();
    }

    async ngOnInit() {
        await this.initTranslation();
        await this.getApiCountries();
        this.displayQueryParamsMessage();
        await this.refresh();
        this.langs.forEach(element => {
            if (element.value === this.user.lang) {
                this.apiLang = element.label;
            }
        });
    }

    ngOnDestroy() {}

    ngAfterViewInit() {}

    async initTranslation() {
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.currencies = event.translations['currency_name'];
            this.requestsEndpoints = event.translations.dashboard.requestsEndpoints;
            const messages = event.translations['messages'];
            this.updateMessagesTranslation(messages);
            const dashboard = event.translations['dashboard'];
            this.updateDashboardTranslation(dashboard);
            this.langs = event.translations['app_languages'];
            // this.dateFormat = event.translations['dateFormat'];
            if (this.user && this.langs) {
                this.langs.forEach(element => {
                    if (element.value === this.user.lang) {
                        this.apiLang = element.label;
                    }
                });
            }
        });
        this.currencies = await this.translate.get('currency_name').toPromise();
        await this.translate.get('messages').toPromise().then(messages => {
            this.updateMessagesTranslation(messages);
        });
        await this.translate.get('dashboard').toPromise().then(dashboard => {
            this.requestsEndpoints = dashboard.requestsEndpoints;
            if (dashboard.superAdmin && dashboard.superAdmin.dailyRequestsTable) {
                this.dailyRequestsColumnsLabels = dashboard.superAdmin.dailyRequestsTable.columns;
                this.dailyRequestsDetailsColumnsLabels = dashboard.superAdmin.dailyRequestsTable.detailsColumns;
                this.dailyRequestsColumns = Object.keys(this.dailyRequestsColumnsLabels);
                this.dailyRequestsDetailsColumns = Object.keys(this.dailyRequestsDetailsColumnsLabels);
            }
        });
        await this.translate.get('app_languages').toPromise().then(languages => {
            this.langs = languages;
        });
    }

    isAdmin() {
        if (this.user) {
            if (this.user.isAdmin()) {
                return true;
            }
        }
        return false;
    }

    displayQueryParamsMessage() {
        this.route.queryParams.subscribe(async params => {
            let checkout, checkout_message;
            if (params['checkout']) {
                checkout = params['checkout'];
                checkout_message = this.checkout;
            } else if (params['checkout_extra']) {
                checkout = params['checkout_extra'];
                checkout_message = this.checkout['extra'];
            } else if (params['checkout_plan']) {
                checkout = params['checkout_plan'];
                checkout_message = this.checkout['plan'];
            } else if (params['checkout_card']) {
                checkout = params['checkout_card'];
                checkout_message = this.checkout['card'];
            }
            if (checkout) {
                await new Promise(resolve => setTimeout(resolve, 2000));
                let type: string;
                let message: string;
                switch (checkout) {
                    case CHECKOUT_ENUM.CANCELED:
                        type = 'danger';
                        message = checkout_message['canceled'];
                        break;
                    case CHECKOUT_ENUM.SUCCESS:
                        type = 'success';
                        message = checkout_message['success'];
                        break;
                    case CHECKOUT_ENUM.FAILED:
                        type = 'danger';
                        message = this.unknown_error;
                }
                if (this._notification && typeof this._notification.create === 'function') {
                    this._notification.create(
                        type,
                        message,
                        {
                            Position: 'top',
                            Style: 'bar',
                            Duration: 10000
                        }
                    );
                }
            }
        });
    }

    updateMessagesTranslation(messages) {
        this.checkout = messages.notification.checkout;
        this.unknown_error = messages.notification.unknown_error;
    }

    updateDashboardTranslation(dashboard) {
        
    }

    getConsumptionLeft() {
        return this.user && this.user.consumptions && this.user.consumptions.length > 0 ? this.user.consumptions[0].today.count : 0;
    }

    getConsumptions(): number {
        let total = this.user && this.user.consumptions && this.user.consumptions.length > 0 ? this.user.consumptions[0].alltogether.count : 0;
        // append child total for API pre-payment
        if (this.user && this.user.planIsApi() && this.user.planTypeIsPrePayment() && this.children) {
            this.children.forEach(child => {
                const endpoints = Object.keys(child.total_credits_consumption);
                total += endpoints.map(endpoint => child.total_credits_consumption[endpoint].count).reduce((a, b) => a + b, 0);
            });
        }
        return total;
    }

    getDailyConsumption() {
        let total = this.user && this.user.consumptions && this.user.consumptions.length > 0 ? this.user.consumptions[0].today.count : 0;
        // append child total for API pre-payment
        if (this.user && this.user.planIsApi() && this.user.planTypeIsPrePayment() && this.children) {
            this.children.forEach(child => {
                const endpoints = Object.keys(child.today_credits_consumption);
                total += endpoints.map(endpoint => child.today_credits_consumption[endpoint].count).reduce((a, b) => a + b, 0);
            });
        }
        return total;
    }

    getNewSaasPlanLeftCredits() {
        if (this.user && this.user.consumptions && this.user.consumptions.length > 0) {
            const bonusLeftCredits = this.user.consumptions[0].bonusCreditsConsumption ? this.user.consumptions[0].bonusCreditsConsumption.left : 0;
            const extraLeftCredits = this.user.consumptions[0].extraCredits ? this.user.consumptions[0].extraCredits.left : 0;
            return bonusLeftCredits + extraLeftCredits;
        }
        return 0;
    }

    getUsedService(): { service: string; consumption: number; total: number; }[] {
        const services: { service: string; consumption: number; total: number; }[] = [];

        if (
            this.user &&
            this.user.consumptions &&
            this.user.consumptions.length > 0 &&
            this.user.consumptions[0].currentPeriod &&
            this.user.consumptions[0].currentPeriod.credits_consumption &&
            this.requestsEndpoints
        ) {
            const endpoints = Object.keys(this.user.consumptions[0].currentPeriod.credits_consumption);
            const endpointsKeys = Object.keys(this.requestsEndpoints);
            endpoints.forEach(endpoint => {
                if (
                    endpointsKeys.includes(endpoint) &&
                    this.requestsEndpoints[endpoint] &&
                    (
                        (
                            this.user.planIsApi() &&
                            this.requestsEndpoints[endpoint].showApi
                        )
                        ||
                        (
                            this.user.planIsSaas() &&
                            this.requestsEndpoints[endpoint].showSaas
                        )
                    )
                ) {
                    const childrenConsumptionEndpoint = this.getChildrenConsumptionsForEndpoint(endpoint);
                    services.push({
                        service: this.user.planIsApi() ? this.requestsEndpoints[endpoint].api : this.requestsEndpoints[endpoint].saas,
                        consumption: this.user.consumptions[0].currentPeriod.credits_consumption[endpoint].count + childrenConsumptionEndpoint.count,
                        total: (this.user.consumptions[0].currentPeriod.credits_consumption[endpoint].count ? this.user.consumptions[0].currentPeriod.credits_consumption[endpoint].count : 0)
                                +
                                (this.user.consumptions[0].currentPeriod.credits_consumption[endpoint].left ? this.user.consumptions[0].currentPeriod.credits_consumption[endpoint].left : 0)
                                +
                                (this.user.consumptions[0].override_plan && this.user.consumptions[0].override_plan.requests && this.user.consumptions[0].override_plan.requests[endpoint] ? this.user.consumptions[0].override_plan.requests[endpoint] : 0)
                                +
                                childrenConsumptionEndpoint.total
                    });
                }
            });

            if (this.user.planIsNewSaas() || (this.user.planIsApi() && this.user.planTypeIsPayAsYouGo())) {
                let i = 0;
                while (i < services.length) {
                    if (services[i].consumption === 0) {
                        services.splice(i, 1);
                    } else {
                        i += 1;
                    }
                }
            }
        }
        return services;
    }

    private getChildrenConsumptionsForEndpoint(endpoint: string): { count: number; total: number; } {
        const cons = {count: 0, total: 0};
        if (this.user && this.user.planIsApi() && this.user.planTypeIsPrePayment() && this.children) {
            this.children.forEach(child => {
                if (child.credits_consumption[endpoint]) {
                    cons.count += child.credits_consumption[endpoint].count;
                    cons.total += child.credits_consumption[endpoint].count
                                + child.credits_consumption[endpoint].left
                                + (child.override_plan && child.override_plan.requests && child.override_plan.requests[endpoint] ? child.override_plan.requests[endpoint] : 0)
                }
            });
        }
        return cons;
    }

    get superAdminRequestsPeriodCount200(): RequestsPerPeriodResponse {
        if (this.superAdminRequestsPeriodCount && this.superAdminRequestsPeriodCount.find(rpc => rpc.httpCode === 200)) {
            return this.superAdminRequestsPeriodCount.find(rpc => rpc.httpCode === 200);
        }
        return Builder(RequestsPerPeriodResponse)
            .httpCode(200)
            .day(
                Builder(RequestsPeriodCountValue)
                .count(0)
                .requestCount({})
                .build()
            )
            .month(
                Builder(RequestsPeriodCountValue)
                .count(0)
                .requestCount({})
                .build()
            )
            .year(
                Builder(RequestsPeriodCountValue)
                .count(0)
                .requestCount({})
                .build()
            )
            .build();
    }
    get superAdminRequestsPeriodCount300(): RequestsPerPeriodResponse {
        if (this.superAdminRequestsPeriodCount && this.superAdminRequestsPeriodCount.find(rpc => rpc.httpCode === 300)) {
            return this.superAdminRequestsPeriodCount.find(rpc => rpc.httpCode === 300);
        }
        return Builder(RequestsPerPeriodResponse)
            .httpCode(300)
            .day(
                Builder(RequestsPeriodCountValue)
                .count(0)
                .requestCount({})
                .build()
            )
            .month(
                Builder(RequestsPeriodCountValue)
                .count(0)
                .requestCount({})
                .build()
            )
            .year(
                Builder(RequestsPeriodCountValue)
                .count(0)
                .requestCount({})
                .build()
            )
            .build();
    }
    get superAdminRequestsPeriodCount400(): RequestsPerPeriodResponse {
        if (this.superAdminRequestsPeriodCount && this.superAdminRequestsPeriodCount.find(rpc => rpc.httpCode === 400)) {
            return this.superAdminRequestsPeriodCount.find(rpc => rpc.httpCode === 400);
        }
        return Builder(RequestsPerPeriodResponse)
            .httpCode(400)
            .day(
                Builder(RequestsPeriodCountValue)
                .count(0)
                .requestCount({})
                .build()
            )
            .month(
                Builder(RequestsPeriodCountValue)
                .count(0)
                .requestCount({})
                .build()
            )
            .year(
                Builder(RequestsPeriodCountValue)
                .count(0)
                .requestCount({})
                .build()
            )
            .build();
    }
    get superAdminRequestsPeriodCount500(): RequestsPerPeriodResponse {
        if (this.superAdminRequestsPeriodCount && this.superAdminRequestsPeriodCount.find(rpc => rpc.httpCode === 500)) {
            return this.superAdminRequestsPeriodCount.find(rpc => rpc.httpCode === 500);
        }
        return Builder(RequestsPerPeriodResponse)
            .httpCode(500)
            .day(
                Builder(RequestsPeriodCountValue)
                .count(0)
                .requestCount({})
                .build()
            )
            .month(
                Builder(RequestsPeriodCountValue)
                .count(0)
                .requestCount({})
                .build()
            )
            .year(
                Builder(RequestsPeriodCountValue)
                .count(0)
                .requestCount({})
                .build()
            )
            .build();
    }

    private async getRequestsPeriodCount(): Promise<RequestsPerPeriodResponse[]> {
        try {
            return (<RequestsPerPeriodResponse[]> await this.http.get(
                `https://api.${environment.baseUrl}/v1/monitoring/users/requestsPerPeriod`
            ).toPromise());
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
        }
    }
    private async getUsersWithLastConsecutiveRequestsError(): Promise<UserLastConsecutiveRequestsError[]> {
        try {
            return (<UserLastConsecutiveRequestsError[]> await this.http.get(
                `https://api.${environment.baseUrl}/v1/monitoring/users/consecutiveRequestsErrors`
            ).toPromise());
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
        }
    }

    onUserMailConsecutiveRequestsErrorClick(mail: string): void {
        const found = this.superAdminUsersWithLastConsecutiveRequestsError.find(item => item.mail === mail);
        if (found) {
            this.selectedUserConsecutiveErrors = found.mail;
            this.selectedUserLastConsecutiveRequestsWithError = found.lastConsecutiveRequestsWithErrors;
        }
    }

    async refresh() {
        this.user = await this.userService.initTransiteoUser(false);
        if (this.user && this.user.isSuperAdmin()) {
            const date = (new Date()).toISOString().substring(0, 10);
            this.setSuperAdminDailyRequestsRows(date, date);
            //this.superAdminData.periodOrders = this.user.superAdminUsersMinimalData.map(md => md.todayOrders).reduce((a, b) => a + b, 0);
            //this.superAdminData.periodRequests = this.user.superAdminUsersMinimalData.map(md => md.todayRequests).reduce((a, b) => a + b, 0);
            [this.superAdminRequestsPeriodCount, this.superAdminUsersWithLastConsecutiveRequestsError] = await Promise.all([this.getRequestsPeriodCount(), this.getUsersWithLastConsecutiveRequestsError()]);
            this.superAdminUsersWithLastConsecutiveRequestsError.sort((a, b) => {
                return b.lastConsecutiveErrorCount - a.lastConsecutiveErrorCount;
            });
        }
        await this.getChildren();
    }

    async getChildren(): Promise<void> {
        this.children = await this.manageChildService.getChildren();
    }

    getTurnoverCurrency(): string {
        if (this.currencies.length === 0) {
            return '';
        }
        const currency = this.currencies.find(c =>
            this.user &&
            this.user.ecommerceHomePageData &&
            c.value === this.user.ecommerceHomePageData.turnover_currency
        );
        if (currency) {
            return currency.label.indexOf(' (') >= 0 ? currency.label.substring(0, currency.label.indexOf(' (')) : currency.label;
        } else if (this.user && this.user.ecommerceHomePageData) {
            return this.user.ecommerceHomePageData.turnover_currency;
        } else {
            return '';
        }
    }

    getCountryName(iso3: string): string {
        if (this.apiCountries.length === 0) {
            return iso3;
        }
        const country = this.apiCountries.find(c => c.value === iso3);
        if (country) {
            return country[`label_${this.translate.currentLang}`] ? country[`label_${this.translate.currentLang}`] : country[`label_en`];
        } else {
            return iso3;
        }
    }

    onOpenDocumentation(): void {
        this.router.navigate([`documentation-guide/${DOCUMENTATIONS_GUIDES.SAAS}`]);
        // window.open('https://transiteo.gitbook.io/web-application-guide/', '_blank');
    }

    getDailyRequestsStatusProcess(httpCode: string, statusContent?: { [key: string]: RequestsNumberPerUser }): { [key: string]: { email: string; firstname: string; lastname: string; httpCode: string; requestsNumber: number; }[] } {
        const res: { [key: string]: { email: string; firstname: string; lastname: string; httpCode: string; requestsNumber: number; }[] } = {};
        if (statusContent) {
            const endpoints = Object.keys(statusContent);
            endpoints.forEach(endpoint => {
                if (! res[endpoint]) {
                    res[endpoint] = [];
                }
                const emails = Object.keys(statusContent[endpoint]);
                emails.forEach(email => {
                    const customer = this.user.superAdminUsersMinimalData.find(md => md.email === email);
                    res[endpoint].push({
                        email,
                        firstname: customer ? customer.firstname : '',
                        lastname: customer ? customer.lastname : '',
                        httpCode,
                        requestsNumber: statusContent[endpoint][email]
                    });
                });
            });
        }
        return res;
    }
    async setSuperAdminDailyRequestsRows(dateStart: string, dateEnd: string): Promise<void> {
        this.dailyRequestSelectedDate = [dateStart, dateEnd];
        const dateStartObj = new Date(dateStart);
        const dateEndObj = new Date(dateEnd);
        
        // 86400000 is 1 day in millis
        const daysDifference = parseInt(((dateEndObj.getTime() - dateStartObj.getTime()) / 86400000).toString(), 10);
        dateStartObj.setUTCDate(dateStartObj.getUTCDate() - 1 - daysDifference);
        dateEndObj.setUTCDate(dateEndObj.getUTCDate() - 1 - daysDifference);
        const [dailyRequestsData, dailyRequestsDataPreviousPeriod] = await Promise.all([
            this.getDailyRequestsData(dateStart, dateEnd),
            this.getDailyRequestsData(dateStartObj.toISOString().substring(0, 10), dateEndObj.toISOString().substring(0, 10))
        ]);
        
        const previousPeriodRequests = this.getTotalRequestsWithCreditsConsummed(dailyRequestsDataPreviousPeriod.requests[200]);
        this.superAdminData.periodRequests = this.getTotalRequestsWithCreditsConsummed(dailyRequestsData.requests[200]);
        this.superAdminData.periodOrders = dailyRequestsData.periodOrders;

        this.superAdminData.previousPeriodRequestsDiff = previousPeriodRequests > 0 ?
            parseInt(((this.superAdminData.periodRequests - previousPeriodRequests) * 100 / previousPeriodRequests).toFixed(0), 10) :
            (this.superAdminData.periodRequests > 0 ? 100 : 0);
        this.superAdminData.previousPeriodOrdersDiff = dailyRequestsDataPreviousPeriod.periodOrders > 0 ?
            parseInt(((this.superAdminData.periodOrders - dailyRequestsDataPreviousPeriod.periodOrders) * 100 / dailyRequestsDataPreviousPeriod.periodOrders).toFixed(0), 10) :
            (this.superAdminData.periodOrders > 0 ? 100 : 0);

        const formattedData = ['200','300','400','500'].map(httpCode => this.getDailyRequestsStatusProcess(httpCode, dailyRequestsData.requests[httpCode]));
        const builtDailyRequestsDetails: { [key: string]: { email: string; firstname: string; lastname: string; httpCode: string; requestsNumber: number; }[] } = {};
        formattedData.forEach(dailyRequestsGroupedByHttpCode => {
            const endpoints = Object.keys(dailyRequestsGroupedByHttpCode);
            endpoints.forEach(endpoint => {
                if (! builtDailyRequestsDetails[endpoint]) {
                    builtDailyRequestsDetails[endpoint] = [];
                }
                builtDailyRequestsDetails[endpoint] = builtDailyRequestsDetails[endpoint].concat(dailyRequestsGroupedByHttpCode[endpoint]);
            });
        });

        // sort the data by httpCode and requestsNumber descending and prepare rows
        const builtDailyRequestsRows: { endpoint: string; totalRequests: number; }[] = [];
        const endpoints = Object.keys(builtDailyRequestsDetails);
        endpoints.forEach(endpoint => {
            builtDailyRequestsDetails[endpoint].sort((a, b) => {
                let aHttpCodeWeight: number;
                let bHttpCodeWeight: number;
                switch (a.httpCode) {
                    case '200':
                        aHttpCodeWeight = 1000000000;
                        break;
                    case '300':
                        aHttpCodeWeight = 1000000;
                        break;
                    case '400':
                        aHttpCodeWeight = 1000;
                        break;
                    case '500':
                        aHttpCodeWeight = 1;
                        break;
                    default:
                        aHttpCodeWeight = 1;
                        break;
                }
                switch (b.httpCode) {
                    case '200':
                        bHttpCodeWeight = 1000000000;
                        break;
                    case '300':
                        bHttpCodeWeight = 1000000;
                        break;
                    case '400':
                        bHttpCodeWeight = 1000;
                        break;
                    case '500':
                        bHttpCodeWeight = 1;
                        break;
                    default:
                        bHttpCodeWeight = 1;
                        break;
                }
                return (b.requestsNumber*bHttpCodeWeight - a.requestsNumber*aHttpCodeWeight);
            });
            builtDailyRequestsRows.push({
                endpoint,
                totalRequests: builtDailyRequestsDetails[endpoint].reduce((a, b) => a + b.requestsNumber, 0)
            });
        });
        // sort builtDailyRequestsRows by requests number descending
        builtDailyRequestsRows.sort((a, b) => {
            return b.totalRequests - a.totalRequests;
        });
        // set rows and its details
        this.dailyRequestsRows = builtDailyRequestsRows;
        this.dailyRequestsDetails = builtDailyRequestsDetails;
    }
    private getTotalRequestsWithCreditsConsummed(requests: { [key: string]: RequestsNumberPerUser }): number {
        const creditsEndpointsList = Object.values(ConsumptionCreditEnum) as string[];
        const endpointsWithCreditsConsumptions = Object.keys(requests).filter(endpoint => creditsEndpointsList.includes(endpoint));
        return endpointsWithCreditsConsumptions.map(endpoint => Object.values(requests[endpoint]).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0);
    }
    async getDailyRequestsData(dateStart: string, dateEnd: string): Promise<DailyRequestsPerUserAndOrdersResponse> {
        try {
            const data = await this.http.get(`https://api.${environment.baseUrl}/v1/monitoring/dailyRequestsPerUserAndOrders?dateStart=${dateStart}&dateEnd=${dateEnd}`).toPromise();
            return data as DailyRequestsPerUserAndOrdersResponse;
        } catch (error) {
            console.error(error);
            return Builder(DailyRequestsPerUserAndOrdersResponse)
                .requests(
                    Builder(DailyRequestsPerUserGroupedByHttpCode)
                    ['200']({})
                    ['300']({})
                    ['400']({})
                    ['500']({})
                    .build()
                )
                .periodOrders(0)
                .build();
        }
    }
    onDailyRequestsDateChanged(dates: string[]): void {
        if (dates && dates.length === 2) {
            this.setSuperAdminDailyRequestsRows(dates[0], dates[1]);
        }
    }
}
