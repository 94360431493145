// Angular Core
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { RouterModule } from '@angular/router';
// Routing
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
// Layouts
import { RootLayout, SimplyWhiteLayoutComponent } from './@pages/connected';
// Layout Service - Required
import { pagesToggleService } from './@pages/services/toggler.service';
// Shared Layout Components
import { SidebarComponent } from './@pages/components/sidebar/sidebar.component';
// import {SearchOverlayComponent} from './@pages/components/search-overlay/search-overlay.component';
import { HeaderComponent } from './@pages/components/header/header.component';
import { CustomTooltipComponent } from './@pages/components/custom-tooltip/custom-tooltip.component';
import { FilterTableComponent } from './@pages/components/filter-table/filter-table.component';
import { CustomTableComponent } from './@pages/components/custom-table/custom-table.component';
import { SharedModule } from './@pages/components/shared.module';
import { pgListViewModule } from './@pages/components/list-view/list-view.module';
import { pgCardModule } from './@pages/components/card/card.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
// Basic Bootstrap Modules
import {
    AccordionModule,
    AlertModule,
    BsDropdownModule,
    ButtonsModule,
    CollapseModule,
    ModalModule,
    ProgressbarModule,
    TabsModule,
    TooltipModule,
    TypeaheadModule,
} from 'ngx-bootstrap';
// Pages Globaly required Components - Optional
import { pgTabsModule } from './@pages/components/tabs/tabs.module';
import { pgSwitchModule } from './@pages/components/switch/switch.module';
import { ProgressModule } from './@pages/components/progress/progress.module';
// Thirdparty Components / Plugins - Optional
import { QuillModule } from 'ngx-quill';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DashboardComponent } from './@pages/connected/dashboard/dashboard.component';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { GlobalConstantsService } from './@pages/services/global-constants.service';
import { PageNotFoundComponent } from './@pages/Common/page-not-found/page-not-found.component';
import { BlankComponent } from './@pages/Common/blank/blank.component';
import { AuthModule } from './@pages/auth/auth.module';
import { HsCodeFinderComponent } from './@pages/connected/hs-code-finder/hs-code-finder.component';
import { UnCodeComponent } from './@pages/connected/ai-classify/un-code/un-code.component';
import { Co2Component } from './@pages/connected/ai-classify/co2/co2.component';
import { CheaperPriceComponent } from './@pages/connected/ai-classify/cheaper-price/cheaper-price.component';
import { WeightDimensionsComponent } from './@pages/connected/ai-classify/weight-dimensions/weight-dimensions.component';
import { RepFinderComponent } from './@pages/connected/ai-classify/rep-finder/rep-finder.component';
import { ProductManagerComponent } from './@pages/connected/product-manager/product-manager.component';
import { CustomerManagerComponent } from './@pages/connected/customers-manager/customers-manager.component';
import { CountryManagerComponent } from './@pages/connected/country-manager/country-manager.component';
import { PaymentManagerComponent } from './@pages/connected/payment-manager/payment-manager.component';
import { DutyCalculationComponent } from './@pages/connected/duty-calculation/duty-calculation.component';
import { pgSelectfx } from './@pages/components/cs-select/select.module';
import { UserSettingsComponent } from './@pages/connected/user-settings/user-settings.component';
import { WelcomeComponent } from './@pages/connected/welcome/welcome.component';
import { OrderManagerComponent } from './@pages/connected/order-manager/order-manager.component';
import { CategoriesManagerComponent } from './@pages/connected/categories-manager/categories-manager.component';
import { MyDocumentsComponent } from './@pages/connected/my-documents/my-documents.component';
import { MonitoringDataComponent } from './@pages/connected/monitoring/monitoring-data/monitoring-data.component';
import { MonitoringUsersComponent } from './@pages/connected/monitoring/monitoring-users/monitoring-users.component';
import { SharedData } from './@pages/Common/SharedData';
import { MessageService } from './@pages/components/message/message.service';
import { MessageModule } from './@pages/components/message/message.module';
import { ProductSearchComponent } from './@pages/connected/product-search/product-search.component';
import { HistoryComponent } from './@pages/connected/history/history.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { GtagModule } from 'angular-gtag';
import { pgSelectModule } from './@pages/components/select/select.module';
import { PlanComponent } from './@pages/connected/plan/plan.component';
import { environment } from '../environments/environment';
import { SubscriptionComponent } from './@pages/connected/subscription/subscription.component';
import { SecurityComponent } from './@pages/connected/security/security.component';
import { LangComponent } from './@pages/connected/lang/lang.component';
import { TwoDigitDecimaNumberDirective } from './@pages/utils/two-digit-decima-number.directive';
import { ThreeDigitDecimaNumberDirective } from './@pages/utils/three-digit-decima-number.directive';
import { ApiKeyComponent } from './@pages/connected/api-key/api-key.component';
import { TransiteoFooterComponent } from './@pages/utils/transiteo-footer/transiteo-footer.component';
import { LogisticProductComponent } from './@pages/connected/product-search/logistic-product/logistic-product.component';
import { SalesProductComponent } from './@pages/connected/product-search/sales-product/sales-product.component';
import { MarketingProductComponent } from './@pages/connected/product-search/marketing-product/marketing-product.component';
import { pgUploadModule } from './@pages/components/upload/upload.module';
import { CardCountriesComponent } from './@pages/connected/card-countries/card-countries.component';
import { KeyIntegrationComponent } from './@pages/connected/key-integration/key-integration.component';
import { IntegrationComponent } from './@pages/connected/integration/integration.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ShipmentComponent } from './@pages/connected/shipment/shipment.component';
import { ShipmentSenderComponent } from './@pages/connected/shipment/shipment-sender/shipment-sender.component';
import { ShipmentResultComponent } from './@pages/connected/shipment/shipment-result/shipment-result.component';
import { ShipmentReceiverComponent } from './@pages/connected/shipment/shipment-receiver/shipment-receiver.component';
import { ShipmentInfoComponent } from './@pages/connected/shipment/shipment-info/shipment-info.component';
import { pgDatePickerModule } from './@pages/components/datepicker/datepicker.module';
import { pgTimePickerModule } from './@pages/components/time-picker/timepicker.module';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { SortLabelPipe } from './@pages/utils/pipes/sort-label.pipe';
import { SecondsMillisecondsPipe } from './@pages/utils/pipes/seconds-milliseconds.pipe';
import { CardCountryComponent } from './@pages/connected/card-countries/card-country/card-country.component';
import {NgxEchartsModule} from 'ngx-echarts';
import * as echarts from 'echarts';
import { MultiUsersComponent } from './@pages/connected/multi-users/multi-users.component';
import { ConfirmPasswordComponent } from './@pages/auth/confirm-password/confirm-password.component';
import { TranslatorComponent } from './@pages/connected/tools/translator/translator.component';
import { ConvertorComponent } from './@pages/connected/tools/convertor/convertor.component';
import { DeleteComponent } from './@pages/connected/delete/delete.component';
import {QuickviewService} from './@pages/components/quickview/quickview.service';
import {QuickviewComponent} from './@pages/components/quickview/quickview.component';
import {AuthInterceptorService} from './@pages/services/auth-interceptor.service';
import {OriginInterceptorService} from './@pages/services/origin-interceptor.service';
import { StepOriginComponent } from './@pages/connected/duty-calculation/step-origin/step-origin.component';
import { StepProductComponent } from './@pages/connected/duty-calculation/step-product/step-product.component';
import { StepOptionsComponent } from './@pages/connected/duty-calculation/step-options/step-options.component';
import { StepTransportComponent } from './@pages/connected/duty-calculation/step-transport/step-transport.component';
import { StepSenderComponent } from './@pages/connected/duty-calculation/step-sender/step-sender.component';
import { StepResultComponent } from './@pages/connected/duty-calculation/step-result/step-result.component';
import {UiSwitchModule} from 'ngx-ui-switch';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
};
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { ColorPickerModule } from 'ngx-color-picker';
import { pgSliderModule } from './@pages/components/slider/slider.module';
import { ShippingComponent } from './@pages/connected/shipping/shipping.component';
import { ShippingStepExpeditionComponent } from './@pages/connected/shipping/step-expedition/step-expedition.component';
import { ShippingStepParcelsComponent } from './@pages/connected/shipping/step-parcels/step-parcels.component';
import { ShippingStepResultComponent } from './@pages/connected/shipping/step-result/step-result.component';
import { EmbedWebsiteModule } from './@pages/components/embed-website/embed-website.module';
import { DocumentationGuideComponent } from './@pages/connected/documentation-guide/documentation-guide.component';
import { CsvMapperComponent } from './@pages/components/csv-mapper/csv-mapper.component';
import { ExportDocumentsComponent } from './@pages/connected/export-documents/export-documents.component';
import { Cn23Component } from './@pages/connected/export-documents/cn23/cn23.component';
import { ExportInvoiceComponent } from './@pages/connected/export-documents/exportInvoice/export-invoice.component';
import { CertificateOfOriginComponent } from './@pages/connected/export-documents/certificateOfOrigin/certificate-of-origin.component';
import { LithiumComponent } from './@pages/connected/export-documents/lithium/lithium.component';
//import { LoadingSpinnerInterceptorService } from './@pages/services/loading-spinner-interceptor.service';
import { TradeTariffRatesComponent } from './@pages/connected/trade-tariff-rates/trade-tariff-rates.component';
import { AiClassifyDocumentsComponent } from './@pages/connected/ai-classify/ai-classify-documents/ai-classify-documents.component';
import { CustomsClearanceComponent } from './@pages/connected/customs-clearance/customs-clearance.component';
import { ImportExportRequirementsComponent } from './@pages/connected/import-export-requirements/import-export-requirements.component';
// import { ShipmentManagerComponent } from './@pages/connected/shipment-manager/shipment-manager.component';

// Hammer Config Override
// https://github.com/angular/angular/issues/10541
export class AppHammerConfig extends HammerGestureConfig {
    overrides = <any>{
        pinch: { enable: false },
        rotate: { enable: false },
    };
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, `${environment.i18n}/`, '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        SimplyWhiteLayoutComponent,
        BlankComponent,
        SidebarComponent,
        QuickviewComponent,
        HeaderComponent,
        CustomTooltipComponent,
        FilterTableComponent,
        CustomTableComponent,
        RootLayout,
        DashboardComponent,
        DocumentationGuideComponent,
        PageNotFoundComponent,
        HsCodeFinderComponent,
        CsvMapperComponent,
        UnCodeComponent,
        Co2Component,
        CheaperPriceComponent,
        WeightDimensionsComponent,
        RepFinderComponent,
        ProductManagerComponent,
        CustomerManagerComponent,
        CountryManagerComponent,
        PaymentManagerComponent,
        DutyCalculationComponent,
        UserSettingsComponent,
        WelcomeComponent,
        OrderManagerComponent,
        CategoriesManagerComponent,
        MyDocumentsComponent,
        ExportDocumentsComponent,
        MonitoringDataComponent,
        MonitoringUsersComponent,
        ProductSearchComponent,
        HistoryComponent,
        CardCountriesComponent,
        PlanComponent,
        SubscriptionComponent,
        SecurityComponent,
        LangComponent,
        TwoDigitDecimaNumberDirective,
        ThreeDigitDecimaNumberDirective,
        ApiKeyComponent,
        TransiteoFooterComponent,
        LogisticProductComponent,
        SalesProductComponent,
        MarketingProductComponent,
        KeyIntegrationComponent,
        IntegrationComponent,
        ShipmentComponent,
        ShipmentSenderComponent,
        ShipmentResultComponent,
        ShipmentReceiverComponent,
        ShipmentInfoComponent,
        SortLabelPipe,
        SecondsMillisecondsPipe,
        CardCountryComponent,
        MultiUsersComponent,
        ConfirmPasswordComponent,
        TranslatorComponent,
        ConvertorComponent,
        DeleteComponent,
        StepOriginComponent,
        StepProductComponent,
        StepOptionsComponent,
        StepTransportComponent,
        StepSenderComponent,
        StepResultComponent,
        ShippingComponent,
        ShippingStepExpeditionComponent,
        ShippingStepParcelsComponent,
        ShippingStepResultComponent,
        Cn23Component,
        ExportInvoiceComponent,
        CertificateOfOriginComponent,
        LithiumComponent,
        TradeTariffRatesComponent,
        AiClassifyDocumentsComponent,
        CustomsClearanceComponent,
        ImportExportRequirementsComponent,
        // ShipmentManagerComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        DragDropModule,
        CommonModule,
        FormsModule,
        HttpModule,
        HttpClientModule,
        SharedModule,
        ProgressModule,
        pgListViewModule,
        pgCardModule,
        RouterModule.forRoot(AppRoutes, { useHash: true }),
        BsDropdownModule.forRoot(),
        AccordionModule.forRoot(),
        AlertModule.forRoot(),
        ButtonsModule.forRoot(),
        CollapseModule.forRoot(),
        ModalModule.forRoot(),
        ProgressbarModule.forRoot(),
        TabsModule.forRoot(),
        TooltipModule.forRoot(),
        TypeaheadModule.forRoot(),
        pgTabsModule,
        PerfectScrollbarModule,
        pgSwitchModule,
        QuillModule.forRoot(),
        AmplifyAngularModule,
        AuthModule,
        ReactiveFormsModule,
        pgSelectfx,
        pgSliderModule,
        MessageModule,
        NgxDatatableModule,
        GtagModule.forRoot({ trackingId: environment.gTag, trackPageviews: true }),
        pgSelectModule,
        EmbedWebsiteModule,
        pgTabsModule,
        pgUploadModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            }
        }),
        pgDatePickerModule,
        pgTimePickerModule,
        NgxIntlTelInputModule,
        NgxEchartsModule.forRoot({ echarts }),
        UiSwitchModule.forRoot({
            color: '#584690',
            size: 'medium',
            checkedTextColor: '#fff'
        }),
        MatAutocompleteModule,
        ColorPickerModule
    ],
    providers: [
        QuickviewService,
        pagesToggleService,
        AmplifyService,
        GlobalConstantsService,
        SharedData,
        MessageService,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: AppHammerConfig,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: OriginInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true
        },
        /*{
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingSpinnerInterceptorService,
            multi: true
        }*/
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
